import React, { useState, useEffect, useContext } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { Webheader, AppSetup } from "../../utils/apis/common/Common";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  const { IMG_URL, signin } = useContext(Context);
  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };

  useEffect(() => {
    getSetup();
  }, []);
  return (
    <>
      <section className="footer-section">
        <div className="container-fluid footer-bg">
          <Container>
            <div className="mb-md-5">
              <Row>
                <Col lg={3} md={6} sm={8}>
                  <div className="footer-col">
                    <div>
                      <Link to={"/"}>
                        {/* <img src={process.env.PUBLIC_URL + "/assets/Image/Logo/Logo_png.png"} className="footer-logo" alt="..." /> */}
                        <img
                          src={IMG_URL + setups?.image}
                          className="footer-logo"
                          alt="..."
                        />
                      </Link>
                    </div>
                    <div className="d-flex mt-2">
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-location-dot"
                          className="font-icon "
                        />
                      </div>
                      <div>
                        <p className="foot-p">{setups?.address}</p>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-phone"
                          className="font-icon"
                        />
                      </div>
                      <div>
                        <p className="foot-p">{setups?.contact_no}</p>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-envelope"
                          className="font-icon"
                        />
                      </div>
                      <div>
                        <p className="foot-p">{setups?.email}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={4} xs={6}>
                  <div className="footer-col">
                    <h6 className="foot-title">Our Features</h6>
                    <Row>
                      <Col xxl={6} lg={8} md={6}>
                        <div className="feature-div">
                          <div className="d-flex">
                            <div>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/scooter.png"
                                }
                                className="feature-fafa"
                                alt="..."
                              />
                            </div>
                            <div>
                              <p className="feature-fafa-subtext">
                                Easy Pickup
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={6} lg={8} md={6}>
                        <div className="feature-div">
                          <div className="d-flex">
                            <div>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/package-box.png"
                                }
                                className="feature-fafa"
                                alt="..."
                              />
                            </div>
                            <div>
                              <p className="feature-fafa-subtext">
                                Free Shipping
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xxl={6} lg={8} md={6}>
                        <div className="feature-div">
                          <div className="d-flex">
                            <div>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/secure-payment.png"
                                }
                                className="feature-fafa"
                                alt="..."
                              />
                            </div>
                            <div>
                              <p className="feature-fafa-subtext">
                                100% Secure
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={6} lg={8} md={6}>
                        <div className="feature-div">
                          <div className="d-flex">
                            <div>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/low-price.png"
                                }
                                className="feature-fafa"
                                alt="..."
                              />
                            </div>
                            <div>
                              <p className="feature-fafa-subtext">
                                Lowest Price
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <div className="footer-col ms-lg-5 ms-md-0">
                    <h6 className="foot-title">Quick Links</h6>
                    <div className="footer-submenu">
                      <div>
                        {signin == true ? (
                          <Link to="/my-account">My Account</Link>
                        ) : (
                          <Link to="/login">My Account</Link>
                        )}
                      </div>
                      <div>
                        <Link to="/about">About Us</Link>
                      </div>
                      <div>
                        <Link to="/contact">Contact Us</Link>
                      </div>
                      <div>
                        <Link to="/blog">Blog</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <div className="footer-col">
                    <h6 className="foot-title">Legal Links</h6>
                    <div className="footer-submenu">
                      <div>
                        <Link to="/terms-condition">Terms & Condition</Link>
                      </div>
                      <div>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </div>
                      <div>
                        <Link to="/return-policy">Return Policy</Link>
                      </div>
                      <div>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <div className="footer-col">
                    <h6 className="foot-title">Social Media</h6>
                    {/* <div>
                      <Link><img src={process.env.PUBLIC_URL + "/assets/Image/Icon/App_store_logo.png"} className="download-btn-img" alt="..." /></Link>
                    </div>
                    <div>
                      <Link><img src={process.env.PUBLIC_URL + "/assets/Image/Icon/Google-Play_logo.png"} className="download-btn-img" alt="..." /></Link>
                    </div> */}

                    <div className="Social_Media_Icon_holder">
                      <div className="icon-holder">
                        <a
                          href="https://www.facebook.com/pureviaindia"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         
                          <img src={process.env.PUBLIC_URL + "/assets/Image/Footer/facebook.png"}  className="font-icon" alt="..." />
                        </a>
                      </div>

                      <div className="icon-holder">
                        <a
                          href="https://www.instagram.com/pureviaindia/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         
                           <img src={process.env.PUBLIC_URL + "/assets/Image/Footer/instagram.png"}  className="font-icon" alt="..." />
                        </a>
                      </div>

                      <div className="icon-holder">
                        <a
                          href="https://twitter.com/pureviaindia"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={process.env.PUBLIC_URL + "/assets/Image/Footer/twitter.png"}  className="font-icon" alt="..." />
                        </a>
                      </div>

                      <div className="icon-holder">
                        <a
                          href="https://www.linkedin.com/company/pureviaindia"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         <img src={process.env.PUBLIC_URL + "/assets/Image/Footer/linkedin.png"}  className="font-icon" alt="..." />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="border-foot"></div>
          <div className="text-center">
            <p className="copyright-p">
              © 2024. All Rights Reserved By Purevia and Develop by
              <span className="copyright-span">
                {" "}
                <a
                  className="copyright-span"
                  href="https://profcymaglobal.com/"
                  target="blank"
                >
                  Profcyma
                </a>{" "}
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
