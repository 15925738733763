import React, { useState, useEffect, useContext } from "react";
import "../../CheckoutProcess/CheckoutProcess.css";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import {
  Country,
  State,
  City,
  Pincode,
  Pincodes,
} from "../../../utils/apis/master/Master";
import { Errors } from "../../../utils/errors";
import { RegxExpression } from "../../../utils/common";
import {
  getAddress,
  getAddressData,
  postAddress,
} from "../../../utils/apis/orderProcess/orderProcess";
import { deleteData } from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditModal from "../../AllModal/EditModal/EditModal";
import DeleteSuccessful from "../../AllModal/EditModal/DeleteSuccessful/DeleteSuccessful";
import AddedSuccessful from "../../AllModal/EditModal/AddedSuccessful/AddedSuccessful";
import Want_to_delete_modal from "../../AllModal/Want_to_delete_modal/Want_to_delete_modal";

const OneAdress = ({
  handleNext,
  setAddressId,
  addressId,
  setAddressData,
  addressData,
}) => {
  const options = [
    { value: "Home", label: "Home" },
    { value: "Work", label: "Work" },
    { value: "Office", label: "Office" },
  ];

  const [showForm, setShowForm] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  console.log(getValues(), "getvalues");

  const [deleteId, setDeleteId] = useState("");

  const handleDeleteRecord = async (id) => {
    // const response = await deleteData(`/useraddress/address-delete/${id}`);
    setDeleteId(id);
    setDeleteModalShow(true);
    console.log(id, "delete id");
    addressGet(); // Refresh addresses after deletion
  };

  const editAddress = async (id) => {
    getSingleAddress(id);
    setAddressDetailId(id);
    // setModalShow(true);
  };

  const getSingleAddress = async (id) => {
    const res = await getAddressData(id);
    if (res?.success) {
      // setSingleAddress(res?.data);
      setValue("name", res?.data?.name);
      setValue("address_type", res?.data?.address_type);
      setValue("flat_home_bldg", res?.data?.flat_home_bldg);
      setValue("col_land_strt", res?.data?.col_land_strt);
      setValue("mobile_number", res?.data?.mobile_number);

      setValue("country_id", {
        value: res?.data?.country_id?.value,
        label: res?.data?.country_id?.label,
      });
      setValue("state_id", {
        value: res?.data?.state_id?.value,
        label: res?.data?.state_id?.label,
      });
      setValue("city_id", {
        value: res?.data?.city_id?.value,
        label: res?.data?.city_id?.label,
      });

      setValue("pincode_id", {
        value: res?.data?.pincode_id?.value,
        label: res?.data?.pincode_id?.label,
      });
      addressGet();
      // reset(res?.data);
    }
  };

  const [address, setAddress] = useState([]);
  const [addressDetailId, setAddressDetailId] = useState(null);
  const [editAddress_id, setEditaddress_id] = useState("");

  const onSubmit = async (data) => {
    const sendData = {
      name: data?.name,
      address_type: data?.address_type?.value,
      mobile_number: data?.mobile_number,
      flat_home_bldg: data?.flat_home_bldg,
      col_land_strt: data?.col_land_strt,
      country_id: data?.country_id?.value,
      state_id: data?.state_id?.value,
      city_id: data?.city_id?.value,
      pincode_id: data?.pincode_id?.value,
    };

    if (editAddress_id) {
      sendData.address_id = editAddress_id;
    }
    const res = await postAddress(sendData);
    if (res?.success) {
      addressGet();
      setEditaddress_id(res.data.id);
      reset();

      setValue("address_type", "");
      setValue("country_id", "");
      setValue("state_id", "");
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };

  // const onSubmitEdit = async (data) => {
  //   const sendData = {
  //     name: data?.name,
  //     address_type: data?.address_type?.value,
  //     mobile_number: data?.mobile_number,
  //     flat_home_bldg: data?.flat_home_bldg,
  //     col_land_strt: data?.col_land_strt,
  //     country_id: data?.country_id?.value,
  //     state_id: data?.state_id?.value,
  //     city_id: data?.city_id?.value,
  //     pincode_id: data?.pincode_id?.value,
  //   };
  //   const res = await postEditAddress(sendData, props.address_id);
  //   if (res?.success) {
  //     props.addressGet();
  //     reset();
  //     setModalShow(true);
  //     setTimeout(() => {
  //       props.onHide();
  //       setModalShow(false);
  //       window.location.reload();
  //     }, 3000);
  //   }
  // };

  const addressGet = async () => {
    const res = await getAddress();
    if (res?.success) {
      setAddress(res?.data);
      if (!addressId) {
        setAddressId(res?.data[0]?.id);
        setAddressData(res?.data[0]);
      }
    }
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [deletemodalShow, setDeleteModalShow] = React.useState(false);
  const [addedmodalShow, setAddedModalShow] = React.useState(false);

  const { Select2Data } = useContext(Context);
  const [countries, setCountries] = useState();
  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [pincodes, setPincode] = useState();
  const getCountries = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res?.data, "country_id", false);
      setCountries(data);
    }
  };
  const getState = async (id) => {
    const res = await State(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "state_id", false);
      setState(data);
      setValue("state_id", "");
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getCity = async (id) => {
    const res = await City(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "city_id", false);
      setCity(data);
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getPincode = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "pincode_id", false);
      setPincode(data);
      setValue("pincode_id", "");
    }
  };

  const [selectedOption, setSelectedOption] = useState("existing");
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleAddress = async (id, data) => {
    await setAddressId(id);
    await setAddressData(data);
  };
  useEffect(() => {
    getCity();
    getCountries();
    getState();
    getPincode();
    addressGet();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <section className="checkout-inner-steps">
        <Row>
          <Col xxl={4} xl={5} lg={5} md={12} xs={12}>
            <div className="existing-adress ">
              {/* <div className="mb-3 bold-text">
                <Form.Check
                  type="radio"
                  label="Use existing address"
                  name="addressOption"
                  checked={selectedOption === "existing"}
                  onChange={() => handleOptionChange("existing")}
                />
              </div> */}

              <div className=" radio_input">
                {address.length !== 0 ? (
                  address.map((value, index) => (
                    <div className="radio_input_field mb-5" key={value?.id}>
                      <Form.Label>{value?.address_type}</Form.Label>
                      <div className="flex-radio-input">
                        <div className="d-flex reddlttt">
                          <Form.Check
                            type="radio"
                            name="name"
                            value={value?.id}
                            defaultChecked={
                              index === 0 || addressId === value?.id
                            }
                            onClick={() => handleAddress(value?.id, value)}
                          />
                          <div>
                            <button
                              type="button"
                              onClick={() => {
                                editAddress(value?.id);
                              }}
                              className="btnnnnnn me-2"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                handleDeleteRecord(value?.id);
                              }}
                              className="btnnnnnn me-2"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-trash" />
                            </button>
                          </div>
                        </div>
                        <Form.Group>
                          <p>{`${value?.name || ""}`}</p>
                        </Form.Group>

                        <Form.Group>
                          <p>{`${value?.mobile_number || ""}`}</p>
                        </Form.Group>

                        <Form.Group>
                          <p>{`${value?.flat_home_bldg || ""}, ${
                            value?.col_land_strt || ""
                          } , ${value?.pincode?.city?.name || ""},${
                            value?.pincode?.state?.name || ""
                          }, ${value?.pincode?.name || ""}`}</p>
                        </Form.Group>
                      </div>

                      {/* <div className="d-flex mt-3">
                        <DeleteSuccessful
                          show={deletemodalShow}
                          onHide={() => setDeleteModalShow(false)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            editAddress(value?.id);
                          }}
                          className="btnnnnnn"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        </button>
                      </div> */}
                    </div>
                  ))
                ) : (
                  <div>No Address Added</div>
                )}
              </div>
            </div>
          </Col>
          <Col xxl={8} xl={7} lg={7} md={12} xs={12}>
            {/* <div>
              <button type="button" onClick={() => setShowForm(!showForm)}>
                Add New address
              </button>
              <br />
            </div> */}
             <Form>
                  <div className="new-adress">
                    <Row>
                      <Col xxl={8} xl={8} lg={12} md={8} xs={12}>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "name",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                              "is-valid": getValues("name"),
                            })}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name of address*</Form.Label>
                          <div
                            className="address-name-select"
                            style={{ width: "100%" }}
                          >
                            <Controller
                              name="address_type"
                              {...register("address_type", {
                                required: "Select Address Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.address_type
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={options}
                                />
                              )}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Flat no./House/bldg*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Flat no./House/bldg"
                            {...register("flat_home_bldg", {
                              required: "Flat no./House/bldg required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.flat_home_bldg,
                              "is-valid": getValues("flat_home_bldg"),
                            })}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Colony/street/landmark</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Colony/street/landmark"
                            {...register("col_land_strt", {
                              required: "Colony/street/landmark required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.col_land_strt,
                              "is-valid": getValues("col_land_strt"),
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col xxl={4} xl={4} lg={12} md={4} xs={12}>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133367!2d73.76940577489273!3d18.52041416917967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1711456588361!5m2!1sen!2sin"
                          width="100%"
                          height="150"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </Col>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>Country</Form.Label>
                        <Controller
                          name="country_id"
                          {...register("country_id", {
                            required: "Select Country",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="Country"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.country_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={countries}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getState(selectedOption.value);
                                setValue("country_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>State</Form.Label>
                        <Controller
                          name="state_id"
                          {...register("state_id", {
                            required: "Select State",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="State"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.state_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={states}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getCity(selectedOption.value);
                                setValue("state_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>City</Form.Label>
                        <Controller
                          name="city_id"
                          {...register("city_id", {
                            required: "Select City",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="City"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.city_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={cities}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getPincode(selectedOption.value);
                                setValue("city_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>Pincode</Form.Label>
                        <Controller
                          name="pincode_id"
                          {...register("pincode_id", {
                            required: "Select Pincode",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="Pincode"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.pincode_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={pincodes}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                        as={Col}
                        md={6}
                      >
                        <Form.Label>Mobile number*</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.mobile_number,
                            "is-valid": getValues("mobile_number"),
                          })}
                          type="text"
                          name="mobile_number"
                          placeholder="Enter Mobile Number"
                          {...register("mobile_number", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      </Form.Group>
                    </Row>
                  </div>

                  {address.length > 0 ? (
                    <div className="main-btn-div ">
                      <button
                        type="button"
                        className="save-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save New Address
                      </button>
                    </div>
                  ) : (
                    <div className="main-btn-div ">
                      <button
                        type="button"
                        className="save-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </Form>

            {address.length > 0 ? (
              showForm && (
                <Form>
                  <div className="new-adress">
                    <Row>
                      <Col xxl={8} xl={8} lg={12} md={8} xs={12}>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "name",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                              "is-valid": getValues("name"),
                            })}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name of address*</Form.Label>
                          <div
                            className="address-name-select"
                            style={{ width: "100%" }}
                          >
                            <Controller
                              name="address_type"
                              {...register("address_type", {
                                required: "Select Address Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.address_type
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={options}
                                />
                              )}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Flat no./House/bldg*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Flat no./House/bldg"
                            {...register("flat_home_bldg", {
                              required: "Flat no./House/bldg required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.flat_home_bldg,
                              "is-valid": getValues("flat_home_bldg"),
                            })}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3  form-grp"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Colony/street/landmark</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Colony/street/landmark"
                            {...register("col_land_strt", {
                              required: "Colony/street/landmark required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.col_land_strt,
                              "is-valid": getValues("col_land_strt"),
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col xxl={4} xl={4} lg={12} md={4} xs={12}>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133367!2d73.76940577489273!3d18.52041416917967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1711456588361!5m2!1sen!2sin"
                          width="100%"
                          height="150"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </Col>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>Country</Form.Label>
                        <Controller
                          name="country_id"
                          {...register("country_id", {
                            required: "Select Country",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="Country"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.country_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={countries}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getState(selectedOption.value);
                                setValue("country_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>State</Form.Label>
                        <Controller
                          name="state_id"
                          {...register("state_id", {
                            required: "Select State",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="State"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.state_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={states}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getCity(selectedOption.value);
                                setValue("state_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>City</Form.Label>
                        <Controller
                          name="city_id"
                          {...register("city_id", {
                            required: "Select City",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="City"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.city_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={cities}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                getPincode(selectedOption.value);
                                setValue("city_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridMobile"
                        className="mb-3"
                      >
                        <Form.Label>Pincode</Form.Label>
                        <Controller
                          name="pincode_id"
                          {...register("pincode_id", {
                            required: "Select Pincode",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="Pincode"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.pincode_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={pincodes}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                        as={Col}
                        md={6}
                      >
                        <Form.Label>Mobile number*</Form.Label>

                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.mobile_number,
                            "is-valid": getValues("mobile_number"),
                          })}
                          type="text"
                          name="mobile_number"
                          placeholder="Enter Mobile Number"
                          {...register("mobile_number", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      </Form.Group>
                    </Row>
                  </div>

                  {address.length > 0 ? (
                    <div className="main-btn-div ">
                      <button
                        type="button"
                        className="save-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save New Address
                      </button>
                    </div>
                  ) : (
                    <div className="main-btn-div ">
                      <button
                        type="button"
                        className="save-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </Form>
              )
            ) : (
              <Form>
                <div className="new-adress">
                  <Row>
                    <Col xxl={8} xl={8} lg={12} md={8} xs={12}>
                      <Form.Group
                        className="mb-3  form-grp"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          {...register("name", {
                            required: "name",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.name,
                            "is-valid": getValues("name"),
                          })}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3  form-grp"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Name of address*</Form.Label>
                        <div
                          className="address-name-select"
                          style={{ width: "100%" }}
                        >
                          <Controller
                            name="address_type"
                            {...register("address_type", {
                              required: "Select Address Type",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.address_type
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={options}
                              />
                            )}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3  form-grp"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Flat no./House/bldg*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Flat no./House/bldg"
                          {...register("flat_home_bldg", {
                            required: "Flat no./House/bldg required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.flat_home_bldg,
                            "is-valid": getValues("flat_home_bldg"),
                          })}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3  form-grp"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Colony/street/landmark</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Colony/street/landmark"
                          {...register("col_land_strt", {
                            required: "Colony/street/landmark required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.col_land_strt,
                            "is-valid": getValues("col_land_strt"),
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col xxl={4} xl={4} lg={12} md={4} xs={12}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133367!2d73.76940577489273!3d18.52041416917967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1711456588361!5m2!1sen!2sin"
                        width="100%"
                        height="150"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Col>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridMobile"
                      className="mb-3"
                    >
                      <Form.Label>Country</Form.Label>
                      <Controller
                        name="country_id"
                        {...register("country_id", {
                          required: "Select Country",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Country"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.country_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={countries}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getState(selectedOption.value);
                              setValue("country_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridMobile"
                      className="mb-3"
                    >
                      <Form.Label>State</Form.Label>
                      <Controller
                        name="state_id"
                        {...register("state_id", {
                          required: "Select State",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="State"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.state_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={states}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getCity(selectedOption.value);
                              setValue("state_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridMobile"
                      className="mb-3"
                    >
                      <Form.Label>City</Form.Label>
                      <Controller
                        name="city_id"
                        {...register("city_id", {
                          required: "Select City",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="City"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.city_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={cities}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getPincode(selectedOption.value);
                              setValue("city_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridMobile"
                      className="mb-3"
                    >
                      <Form.Label>Pincode</Form.Label>
                      <Controller
                        name="pincode_id"
                        {...register("pincode_id", {
                          required: "Select Pincode",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Pincode"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.pincode_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={pincodes}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3 "
                      controlId="exampleForm.ControlInput1"
                      as={Col}
                      md={6}
                    >
                      <Form.Label>Mobile number*</Form.Label>

                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.mobile_number,
                          "is-valid": getValues("mobile_number"),
                        })}
                        type="text"
                        name="mobile_number"
                        placeholder="Enter Mobile Number"
                        {...register("mobile_number", {
                          required: "Field is required",
                          minLength: {
                            value: 10,
                            message: "Number should be at least 10 characters",
                          },
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    </Form.Group>
                  </Row>
                </div>

                {address.length > 0 ? (
                  <div className="main-btn-div ">
                    <button
                      type="button"
                      className="save-btn"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save New Address
                    </button>
                  </div>
                ) : (
                  <div className="main-btn-div ">
                    <button
                      type="button"
                      className="save-btn"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Col>
        </Row>
        <div className="main-btn-div ">
          {address.length > 0 && (
            <button type="button" className="save-btn" onClick={handleNext}>
              Next
            </button>
          )}
        </div>
        <AddedSuccessful
          show={addedmodalShow}
          onHide={() => setAddedModalShow(false)}
        />
      </section>
      <EditModal
        address_id={addressDetailId}
        addressGet={addressGet}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Want_to_delete_modal
        show={deletemodalShow}
        delete_id={deleteId}
        addressGet={addressGet}
        onHide={() => setDeleteModalShow(false)}
      />
    </>
  );
};

export default OneAdress;
