import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "../CheckoutProcess.css";
const TwoDelivery = ({ handlePrevious, handleNext }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("10:00");

  const handleDateChange = (date) => {
    const today = new Date();
    if (date <= today) {
      setStartDate(today);
    } else {
      setStartDate(date);
    }
  };
  return (
    <>
      <section className="delivary-step">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={8} xs={12}>
            <div className="checkout-inner-steps">
              <div>
                <Form.Group
                  className="mb-4 "
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xxl={2} xl={3} lg={3} md={4} xs={12}>
                      <Form.Label>
                        Select date<span className="star">*</span>
                      </Form.Label>
                    </Col>
                    <Col xxl={10} xl={9} lg={9} md={8} xs={12}>
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        icon="fa fa-calendar"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-4 "
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col xxl={2} xl={3} lg={3} md={4} xs={12}>
                      <Form.Label>
                        Select time<span className="star">*</span>
                      </Form.Label>
                    </Col>
                    <Col xxl={10} xl={9} lg={9} md={8} xs={12}>
                      <TimePicker onChange={onChange} value={value} />
                    </Col>
                  </Row>
                </Form.Group>
              </div>

              <div className="main-btn-div ">
                <button className="back-btn" onClick={handlePrevious}>
                  Back
                </button>
                <button className="save-btn" onClick={handleNext}>
                  {" "}
                  Save & Next
                </button>
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
            <div className="img-div"></div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default TwoDelivery;
