import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleUser, fas } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import "../AllModal/ProductSuccessful.js";
import LogOutModal from "../AllModal/LogOutModal";
import { ListGroup } from "react-bootstrap";
import ProductSuccessful from "../AllModal/ProductSuccessful.js";
import Form from "react-bootstrap/Form";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { CartQuantity, getCart } from "../../utils/apis/addToCart/addToCart";
import {
  getAllProducts,
  getAllPureProducts,
} from "../../utils/apis/Product/Product.js";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  getWishlist,
  addWishlist,
} from "../../utils/apis/Wishlist/Wishlist.js";
import Heart_Component from "../Common-Component/Heart_Component/Heart_Component";
import SupermarketModal from "../Common-Component/Supermarket-modal/SupermarketModal";
import { AddToCart } from "../../utils/apis/addToCart/addToCart.js";
import { Webheader, AppSetup } from "../../utils/apis/common/Common";
library.add(fas);

const Header = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    IMG_URL,
    triggerCartUpdate,
    wishlistCount,
    triggerWishlistUpdate,
    setAddCartModal,
    addCartModal,
    getData,
    wishlistContext,
    changeQuantity,
    setChangeQuantity,
  } = useContext(Context);

  // const products = [
  //   {
  //     id: 1,
  //     name: "Pen",
  //     imagePath: "/assets/Image/Homepage/products/review.png",
  //   },
  //   {
  //     id: 2,
  //     name: "Pencil",
  //     imagePath: "/assets/Image/Homepage/products/cello-tape.png",
  //   },
  //   {
  //     id: 3,
  //     name: "Notebook",
  //     imagePath: "/assets/Image/Homepage/products/marker-png.png",
  //   },
  // ];

  const [products, setProducts] = useState([]);

  const getAllProduct = async () => {
    const res = await getAllPureProducts();
    if (res?.success) {
      setProducts(res?.data);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const productscart = [
    {
      id: 1,
      name: "Pen",
      imagePath: "/assets/Image/Homepage/products/review.png",
    },
    {
      id: 2,
      name: "Pencil",
      imagePath: "/assets/Image/Homepage/products/cello-tape.png",
    },
  ];

  const [modalShow, setModalShow] = React.useState(false);
  const [values, setValues] = useState(products.map(() => 0));

  const decrement = (index) => {
    const newValues = [...values];
    if (newValues[index] > 0) {
      newValues[index]--;
      setValues(newValues);
    }
  };

  const increment = (index) => {
    const newValues = [...values];
    newValues[index]++;
    setValues(newValues);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showone, setShowone] = useState(false);
  const handleCloseone = () => setShowone(false);
  const handleShowone = () => setShowone(true);

  const [logoutShow, setlogoutShow] = React.useState(false);

  const [showList, setShowList] = useState(true);

  const navigate = useNavigate();
  const handleLogout = async () => {
    await Cookies.remove("purevia_security", { path: "/" });
    await setSignin(false);
    await navigate("/");
    window.location.reload();
  };

  const handleItemClick = (product) => {
    setSearch(product.label); // Set the search bar to the clicked item
    setShowList(false); // Hide the list when an item is clicked
    navigate(`/product-details/${product.value}`);
  };

  var [totalPrice, setTotalPrice] = useState(0);
  var [totalTax, setTotalTax] = useState(0);
  var [discount, setDiscount] = useState(0);
  var [subTotal, setSubTotal] = useState(0);
  var [Total, setTotal] = useState(0);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const [cart, setCart] = useState([]);
  const getCartQuantity = async (id, type) => {
    setError("code", "");
    setChangeQuantity(!changeQuantity);
    await CartQuantity(id, type);
    triggerCartUpdate();
    if (Cookies.get("purevia_cart")) {
      if (JSON.parse(Cookies.get("purevia_cart")).length === 0) {
        // navigate('/');
      }

      setCart(JSON.parse(Cookies.get("purevia_cart")));
      withoutCart();
    }
    if (signin) {
      await cartGet();
    }
  };

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      if (res?.data.length === 0) {
        // navigate('/');
      }
      setCart(res?.data);
    }
  };

  const withoutCart = async () => {
    if (Cookies.get("purevia_cart")) {
      if (JSON.parse(Cookies.get("purevia_cart")).length === 0) {
        // navigate('/');
      }
      await setCart(JSON.parse(Cookies.get("purevia_cart")));
    }
  };
  const [wishlist, setWishlist] = useState();

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [
    Cookies.get("purevia_cart"),
    signin,
    cartCount,
    wishlist,

    changeQuantity,
  ]);

  const getWishlisted = async () => {
    const res = await getWishlist();
    if (res?.success) {
      setWishlist(res?.data);
    }
  };

  const addToCart = async (product_id) => {
    const data = { product_id: product_id };
    const res = await AddToCart(data);
    if (res?.success) {
      await getWishlisted();
      setAddCartModal(true);
      setTimeout(async () => {
        setAddCartModal(false);
      }, 2000);
    } else {
      handleClose(false);
      // navigate("/cart");
    }
  };

  useEffect(() => {
    getWishlisted();
  }, [wishlistCount]);

  const [toggleShow, setToggleShow] = useState(false);
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const getProducts = async () => {
    if (search) {
      const res = await getData(`/without-login/product?term=${search}`);
      if (res?.success) {
        setProductList(res?.data);
      }
    } else {
      setToggleShow(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [search]);

  useEffect(() => {
    getSetup();
  }, []);

  const [show2, setShow2] = React.useState(true);

  function handleClick(el) {
    el.classList.toggle("float-label-top");
  }

  // const handleNavLinkClick = () => {
  //   window.location.reload();
  // };
  // const [search, setSearch] = useState("");
  // const [toggleShow, setToggleShow] = useState(false);
  const dropdownRef = useRef(null);
  // const Navigate = useNavigate();

  // Function to handle clicks outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleShow(false);
    }
  };

  // Add event listener for clicks outside the dropdown when it is shown
  useEffect(() => {
    if (toggleShow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    // Clean up event listener on component unmount or when dropdown is hidden
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleShow]);

  const Logouttt = () => {
    setlogoutShow(true);
  };
  return (
    <section className="all-header-section">
      <div className="top-header">
        <div className="container-fluid background">
          <Container>
            <Navbar expand="lg" className="p-0">
              <Navbar.Brand>
                <p className="phone-no">
                  Our Phone Number : {setups?.contact_no}
                </p>
              </Navbar.Brand>
              <Navbar.Collapse
                id="basic-navbar-nav-top"
                className="justify-content-end"
              >
                {signin ? (
                  <Link to={"/my-account"}>
                    <p className="log-in-btn me-3">
                      <FontAwesomeIcon
                        icon={faCircleUser}
                        className="circleuser me-1"
                      />{" "}
                      <span className="prf">Profile</span>
                    </p>
                  </Link>
                ) : (
                  ""
                )}

                {signin ? (
                  <>
                    <p className="log-in-btn" onClick={Logouttt}>
                      <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />{" "}
                      Log Out
                    </p>
                  </>
                ) : (
                  <>
                    <Link to={"/login"}>
                      {" "}
                      <p className="log-in-btn me-3">
                        <FontAwesomeIcon icon={faUser} /> Log In
                      </p>
                    </Link>
                  </>
                )}

                <LogOutModal
                  show={logoutShow}
                  onHide={() => setlogoutShow(false)}
                />
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </div>
      {/* <!-- end top-header section  --> */}

      {/* <!-- start head-logo section  --> */}
      <div className="head-logo">
        <div className="container-fluid background">
          <Container>
            <Row>
              <Col md={3} xs={3}>
                <Link to="/">
                  <img
                    // src={
                    //   process.env.PUBLIC_URL +
                    //   "/assets/Image/Logo/Logo_png.png"
                    // }
                    src={IMG_URL + setups?.image}
                    className="logo-img"
                    alt="Netpurti Logo"
                  />
                </Link>
              </Col>
              <Col md={6} xs={9} className="search-bar-box">
                <div className="text-center search  searchmainnn">
                  <div className="input-group mt-md-2">
                    <Form.Control
                      type="text"
                      placeholder="Search here"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setToggleShow(true);
                      }}
                      onFocus={() => setShowList(true)} // Show the list when the search bar is focused
                    />
                    <div>
                      <button
                        className="btn search-btn"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          setSearch("");
                          setToggleShow(false);
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  {toggleShow && (
                    <ListGroup ref={dropdownRef}>
                      {productList?.map((product) => (
                        <div
                          onClick={() => {
                            console.log(product.value);
                            setToggleShow(false);
                            setSearch("");
                            navigate(`/product-details/${product.value}`);
                          }}
                          key={product.value}
                        >
                          <ListGroup.Item className="listtexxt">
                            {product.label}
                          </ListGroup.Item>
                        </div>
                      ))}
                    </ListGroup>
                  )}
                </div>
              </Col>
              <Col md={3} xs={12}>
                <div className="d-flex justify-content-end mt-2 ">
                  <button
                    className="btn p-0 offcanvas-btn"
                    onClick={handleShow}
                  >
                    <div className="badges-main heart-bg">
                      <div className="position-relative">
                        <div className="badges-img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/Icon/black-heart.png"
                            }
                            className="icon-img"
                            alt="Favorite"
                          />
                        </div>
                        <span className="position-absolute top start translate-middle bg-danger1 border border-light rounded-circle">
                          {wishlistCount}
                        </span>
                      </div>
                    </div>
                  </button>
                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="end"
                    className="whistlist-ofcanvas"
                  >
                    {signin ? (
                      <div>
                        <Offcanvas.Header closeButton>
                          <p>{wishlistCount} Item</p>
                          <Offcanvas.Title>Wishlist</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div>
                            <div className="main-div main-div2">
                              {wishlist?.map((item, index) => (
                                <div className="pro-div" key={item.id}>
                                  <Row>
                                    <Col
                                      xxl={4}
                                      xl={4}
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={4}
                                    >
                                      <div className="img-div">
                                        <img
                                          src={IMG_URL + item?.product?.image1}
                                          className="cart-img"
                                          alt={item?.product?.name}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={7}
                                      xl={7}
                                      lg={7}
                                      md={7}
                                      sm={7}
                                      xs={6}
                                    >
                                      <div className="product-info">
                                        <p>{item?.product?.name}</p>
                                        <p className="prices">
                                          Rs. {item?.product?.s_price}
                                        </p>
                                        <div
                                          id={`second-div-${index}`}
                                          className="main-img-div"
                                        ></div>
                                        <div className="button-div">
                                          <button
                                            className="add-to-card"
                                            onClick={() => {
                                              addToCart(item?.product?.id);
                                              setAddCartModal(true);
                                            }}
                                          >
                                            Add to cart
                                          </button>
                                        </div>

                                        <ProductSuccessful
                                          show={modalShow}
                                          onHide={() => setModalShow(false)}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={1}
                                      lg={1}
                                      md={1}
                                      sm={1}
                                      xs={2}
                                    >
                                      <Heart_Component
                                        product={item?.product}
                                        getWishlisted={getWishlisted}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                            {wishlist?.length > 0 ? (
                              <Link to="/wishlist">
                                <div className="px-3">
                                  <button
                                    className="add-to-card view-btn my-3"
                                    onClick={handleClose}
                                  >
                                    View Wishlist
                                  </button>
                                </div>
                              </Link>
                            ) : (
                              <div style={{ textAlign: "center" }}>
                                <p>Your wishlist is empty</p>
                              </div>
                            )}
                          </div>
                        </Offcanvas.Body>
                      </div>
                    ) : (
                      <div>
                        <Offcanvas.Header closeButton>
                          <p>{wishlistCount} Item</p>
                          <Offcanvas.Title>Wishlist</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div>
                            <div className="main-div main-div2">
                              {wishlist?.map((item, index) => (
                                <div className="pro-div" key={item.id}>
                                  <Row>
                                    <Col
                                      xxl={4}
                                      xl={4}
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={4}
                                    >
                                      <div className="img-div">
                                        <img
                                          src={IMG_URL + item?.product?.image1}
                                          className="cart-img"
                                          alt={item?.product?.name}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={7}
                                      xl={7}
                                      lg={7}
                                      md={7}
                                      sm={7}
                                      xs={6}
                                    >
                                      <div className="product-info">
                                        <p>{item?.product?.name}</p>
                                        <p className="prices">
                                          Rs. {item?.product?.s_price}
                                        </p>
                                        <div
                                          id={`second-div-${index}`}
                                          className="main-img-div"
                                        ></div>
                                        <div className="button-div">
                                          <button
                                            className="add-to-card"
                                            onClick={() => {
                                              addToCart(item?.product?.id);
                                              setAddCartModal(true);
                                            }}
                                          >
                                            Add to cart
                                          </button>
                                        </div>

                                        <ProductSuccessful
                                          show={modalShow}
                                          onHide={() => setModalShow(false)}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={1}
                                      lg={1}
                                      md={1}
                                      sm={1}
                                      xs={2}
                                    >
                                      <Heart_Component
                                        product={item?.product}
                                        getWishlisted={getWishlisted}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                            {wishlist?.length > 0 ? (
                              <Link to="/wishlist">
                                <div className="px-3">
                                  <button
                                    className="add-to-card view-btn my-3"
                                    onClick={handleClose}
                                  >
                                    View Wishlist
                                  </button>
                                </div>
                              </Link>
                            ) : (
                              <div style={{ textAlign: "center" }}>
                                <p>Your wishlist is empty</p>
                              </div>
                            )}
                          </div>
                        </Offcanvas.Body>
                      </div>
                    )}
                  </Offcanvas>
                  <button
                    className="btn p-0 offcanvas-btn"
                    onClick={handleShowone}
                  >
                    <div className="badges-main2">
                      <div className="position-relative">
                        <div className="badges-img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/Icon/cart.png"
                            }
                            className="icon-img"
                            alt="Cart"
                          />
                        </div>
                        <span className="position-absolute top start translate-middle bg-danger1 border border-light rounded-circle">
                          {cartCount}
                        </span>
                      </div>
                    </div>
                  </button>

                  <Offcanvas
                    show={showone}
                    onHide={handleCloseone}
                    placement="end"
                    className="whistlist-ofcanvas"
                  >
                    {signin ? (
                      <div>
                        <Offcanvas.Header closeButton>
                          <p>{cart?.length} items</p>
                          <Offcanvas.Title>Cart</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          {cartCount > 0 ? (
                            <div className="main-div">
                              <div className="cartttdataaaover">
                                {(() => {
                                  return cart.map((item, index) => {
                                    const itemPrice = item?.product?.sale
                                      ?.sale_price
                                      ? Number(
                                          item?.product?.sale?.sale_price
                                        ) * item?.quantity
                                      : Number(item?.product?.s_price) *
                                        item?.quantity;

                                    const itemTax =
                                      (parseInt(
                                        item.product?.product_detail
                                          .tax_percentage?.name
                                      ) *
                                        itemPrice) /
                                      100;

                                    subTotal += itemPrice;
                                    totalTax += itemTax;
                                    totalPrice = subTotal + totalTax + 40;

                                    return (
                                      <div className="pro-div" key={item.id}>
                                        <Row>
                                          <Col
                                            xxl={4}
                                            xl={4}
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={4}
                                          >
                                            <div className="img-div">
                                              <img
                                                src={
                                                  IMG_URL + item.product?.image1
                                                }
                                                className="cart-img"
                                                alt={item?.name}
                                              />
                                            </div>
                                          </Col>
                                          <Col
                                            xxl={8}
                                            xl={8}
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={8}
                                          >
                                            <div className="product-info">
                                              <p>{item.product?.name}</p>
                                              <p className="prices">
                                                Rs. {itemPrice}
                                              </p>
                                              <div
                                                id={`second-div-${index}`}
                                                className="main-img-div"
                                              >
                                                <div className="d-flex add-cart-box">
                                                  <button
                                                    type="button"
                                                    className="minus btn"
                                                    onClick={async () => {
                                                      await getCartQuantity(
                                                        item.id,
                                                        "minus"
                                                      );
                                                    }}
                                                  >
                                                    -
                                                  </button>
                                                  <div className="quantity">
                                                    {item.quantity}
                                                  </div>
                                                  <button
                                                    type="button"
                                                    className="add btn"
                                                    onClick={async () => {
                                                      await getCartQuantity(
                                                        item.id,
                                                        "add"
                                                      );
                                                    }}
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col
                                            xxl={12}
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="text-end"
                                          >
                                            <button className="icon-btn">
                                              {/* <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> */}
                                            </button>
                                            <div className="product-info">
                                              <p>Selling Price</p>
                                              <p className="price">
                                                Rs.{" "}
                                                {item.product?.sale !== null
                                                  ? item?.product?.sale
                                                      ?.sale_price
                                                  : item?.product?.s_price}
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  });
                                })()}
                              </div>

                              <div className=" tbclass">
                                <Table bordered>
                                  <tr>
                                    <th>Tax</th>
                                    <td>Rs. {totalTax.toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <th>Subtotal</th>
                                    <td>Rs. {subTotal.toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <th>Shipping</th>
                                    <td>Rs. 40.00</td>
                                  </tr>
                                  <tr>
                                    <th>Total</th>
                                    <td>Rs. {totalPrice.toFixed(2)}</td>
                                  </tr>
                                </Table>
                                {cartCount !== 0 && (
                                  <Link to="/cart">
                                    <button
                                      className="add-to-card view-btn my-3"
                                      onClick={handleCloseone}
                                    >
                                      View cart and Checkout
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <p>Your Cart is empty</p>
                            </div>
                          )}
                        </Offcanvas.Body>
                      </div>
                    ) : (
                      <div>
                        <Offcanvas.Header closeButton>
                          <p>{cart?.length} items</p>
                          <Offcanvas.Title>Cart</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          {cartCount > 0 ? (
                            <div className="main-div">
                              <div className="cartttdataaaover">
                                {(() => {
                                  return cart.map((item, index) => {
                                    const itemPrice = item?.product?.sale
                                      ?.sale_price
                                      ? Number(
                                          item?.product?.sale?.sale_price
                                        ) * item?.quantity
                                      : Number(item?.price) * item?.quantity;

                                    const itemTax =
                                      (parseInt(item.tax_percent) * itemPrice) /
                                      100;

                                    subTotal += itemPrice;
                                    totalTax += itemTax;
                                    totalPrice = subTotal + totalTax + 40;

                                    return (
                                      <div className="pro-div" key={item.id}>
                                        <Row>
                                          <Col
                                            xxl={4}
                                            xl={4}
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={4}
                                          >
                                            <div className="img-div">
                                              <img
                                                src={IMG_URL + item?.image}
                                                className="cart-img"
                                                alt={item?.name}
                                              />
                                            </div>
                                          </Col>
                                          <Col
                                            xxl={6}
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={6}
                                          >
                                            <div className="product-info">
                                              <p>{item.name}</p>
                                              <p className="prices">
                                                Rs. {itemPrice}
                                              </p>
                                              <div
                                                id={`second-div-${index}`}
                                                className="main-img-div"
                                              >
                                                <div className="d-flex add-cart-box">
                                                  <button
                                                    type="button"
                                                    className="minus btn"
                                                    onClick={async () => {
                                                      await getCartQuantity(
                                                        item.id,
                                                        "minus"
                                                      );
                                                    }}
                                                  >
                                                    -
                                                  </button>
                                                  <div className="quantity">
                                                    {item.quantity}
                                                  </div>
                                                  <button
                                                    type="button"
                                                    className="add btn"
                                                    onClick={async () => {
                                                      await getCartQuantity(
                                                        item.id,
                                                        "add"
                                                      );
                                                    }}
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col
                                            xxl={2}
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={12}
                                            className="text-end"
                                          >
                                            <button className="icon-btn">
                                              {/* <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> */}
                                            </button>
                                            <p className="price">
                                              Rs.{" "}
                                              {item.product?.sale !== null
                                                ? item?.price
                                                : item?.product?.sale
                                                    ?.sale_price}
                                              {/* {item?.price} */}
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  });
                                })()}
                              </div>

                              <div className="tbclass">
                                <Table bordered>
                                  <tr>
                                    <th>Tax</th>
                                    <td>Rs. {totalTax.toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <th>Subtotal</th>
                                    <td>Rs. {subTotal.toFixed(2)}</td>
                                  </tr>
                                  <tr>
                                    <th>Shipping</th>
                                    <td>Rs. 40.00</td>
                                  </tr>
                                  <tr>
                                    <th>Total</th>
                                    <td>Rs. {totalPrice.toFixed(2)}</td>
                                  </tr>
                                </Table>
                                {cartCount !== 0 && (
                                  <Link to="/login">
                                    <button
                                      className="add-to-card view-btn my-3"
                                      onClick={handleCloseone}
                                    >
                                      View cart and Checkout
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <p>Your Cart is empty</p>
                            </div>
                          )}
                        </Offcanvas.Body>
                      </div>
                    )}
                  </Offcanvas>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* <!-- end head-logo section  --> */}
      <div className="main-nav">
        <Navbar expand="lg" className="navbar-background">
          <Container fluid>
            <div
              className="container cont-pos main-menu rightopn"
              id="mainMenu"
            >
              <Navbar.Toggle
                className="nav-tog-space toggle-btn"
                aria-controls="navbarTogglerDemo02"
              >
                <FontAwesomeIcon icon="fa-solid fa-bars" />
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className="mx-auto navbar-menu-bg main-nav-more mb-lg-0 mb-2"
                  id="autoNav"
                >
                  <Nav.Item>
                    <NavLink
                      className="nav-menu "
                      as={Link}
                      to="/"
                      onClick={handleNavItemClick}
                    >
                      Home
                    </NavLink>
                  </Nav.Item>

                  {/* <Nav.Item> */}
                  {/* <NavDropdown className="hrderropp" title="Products" id="basic-nav-dropdown"> */}
                  <NavDropdown
                    className="hrderropp"
                    title="Products"
                    id="basic-nav-dropdown"
                  >
                    {products?.map((product, index) => (
                      <NavDropdown.Item key={index}>
                        <Link to={`/product-details/${product?.id}`}>
                          <p> {product?.name}</p>
                        </Link>
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>

                  <Nav.Item>
                    <NavLink
                      className="nav-menu"
                      as={Link}
                      to="/about"
                      onClick={handleNavItemClick}
                    >
                      About Us
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink
                      className="nav-menu"
                      as={Link}
                      to="/contact"
                      onClick={handleNavItemClick}
                    >
                      Contact Us
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      className="nav-menu"
                      as={Link}
                      to="/blog"
                      onClick={handleNavItemClick}
                    >
                      Blog
                    </NavLink>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <NavLink
                      className="nav-menu"
                      as={Link}
                      to="/becomeseller"
                      onClick={handleNavItemClick}
                    >
                      Become a Seller
                    </NavLink>
                  </Nav.Item> */}
                  <div className="mobile-view-btn mt-1">
                    {signin ? (
                      <Link to={"/my-account"}>
                        <p className="log-in-btn me-3 mb-2">
                          <FontAwesomeIcon
                            icon={faCircleUser}
                            className="circleuser me-1"
                          />{" "}
                          <span className="prf">Profile</span>
                        </p>
                      </Link>
                    ) : (
                      ""
                    )}
                    {signin ? (
                      <>
                        <p className="log-in-btn" onClick={Logouttt}>
                          <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />{" "}
                          Log Out
                        </p>
                      </>
                    ) : (
                      <>
                        <Link to={"/login"}>
                          {" "}
                          <p className="log-in-btn me-3">
                            <FontAwesomeIcon icon={faUser} /> Log In
                          </p>
                        </Link>
                      </>
                    )}
                    <LogOutModal
                      show={logoutShow}
                      onHide={() => setlogoutShow(false)}
                    />
                  </div>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>
      </div>
      <SupermarketModal
        show={addCartModal}
        onHide={() => setAddCartModal(false)}
      />
    </section>
  );
};

export default Header;
